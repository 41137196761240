import {Injectable} from '@angular/core';
import {CustomError} from '@shared/error/custom-error';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Broadcaster, BroadcasterService, ListOfBroadcasterResponse} from '@shared/generated/api/broadcaster';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {LocalStorageKeys} from '@shared/enums/local-storage-keys';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private broadcaster$: BehaviorSubject<Broadcaster> = new BehaviorSubject<Broadcaster>({} as Broadcaster);
    private broadcasters$: BehaviorSubject<Array<Broadcaster>> = new BehaviorSubject<Array<Broadcaster>>([]);

    constructor(private broadcasterService: BroadcasterService) {
    }

    public get currentBroadcaster$(): Observable<Broadcaster> {
        return this.broadcaster$.asObservable();
    }

    public get currentBroadcaster(): Broadcaster {
        return this.broadcaster$.getValue();
    }

    public set currentBroadcaster(broadcaster: Broadcaster) {
        if (!broadcaster.id) return;
        this.broadcaster$.next(broadcaster);
    }

    public get availableBroadcasters$(): Observable<Array<Broadcaster>> {
        return this.broadcasters$.asObservable();
    }

    public get availableBroadcasters(): Array<Broadcaster> {
        return this.broadcasters$.getValue();
    }

    public setToken(token: string): void {
        const decoded: IGenericObject = JSON.parse(atob(token.split('.')[1]));
        if (decoded.broadcasterIds.length) {
            this.broadcasterService.getBroadcasters({}).subscribe(
                {
                    next: (response: ListOfBroadcasterResponse): void => {
                        this.broadcasters$.next(response.data || []);
                        this.setCurrentBroadcaster(response.data || []);
                    },
                    error: () => throwError(() => new CustomError('no broadcasterIds provided', 'StateService')),
                },
            );
        }
    }

    public setBroadcasterByTenantId(id: number): void {
        const tenantId: number = LocalStorageService.getLocalStorage<number>(LocalStorageKeys.TENANT_ID);
        const newBroadcaster: Broadcaster | undefined
            = this.availableBroadcasters.find((broadcaster: Broadcaster) => broadcaster.id === id);
        const previousBroadcaster: Broadcaster | undefined
            = this.availableBroadcasters.find((broadcaster: Broadcaster) => broadcaster.id === tenantId);
        if (newBroadcaster) {
            this.currentBroadcaster = newBroadcaster;
        } else if (previousBroadcaster) {
            this.currentBroadcaster = previousBroadcaster;
        } else {
            this.setFirstBroadcaster();
        }
    }

    public setFirstBroadcaster(): void {
        this.currentBroadcaster = this.availableBroadcasters[0];
    }

    private setCurrentBroadcaster(broadcasters: Array<Broadcaster>): void {
        const tenantId: number = LocalStorageService.getLocalStorage<number>(LocalStorageKeys.TENANT_ID);
        if (tenantId) {
            const currentBroadcaster: Broadcaster | undefined = broadcasters.find(
                (broadcaster: Broadcaster) => broadcaster.id.toString() === tenantId?.toString(),
            );

            // check if storedBroadcaster is valid for the user otherwise take the first one
            if (currentBroadcaster) {
                this.currentBroadcaster = currentBroadcaster;

                return;
            }
        }
        this.setFirstBroadcaster();
    }
}
