export class LocalStorageService {

    public static getLocalStorage<T>(key: string): T {
        const content: string | null = localStorage.getItem((key));

        return content ? JSON.parse(content) : null;
    }

    public static setLocalStorage<T>(key: string, data: T): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public static removeFromLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
}
