import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {parseISO} from 'date-fns';

enum TimeZone {
    EuropeBerlin = 'Europe/Berlin',
    AustraliaSydney = 'Australia/Sydney',
}

type DateInput = string | Date;

class CoreTimezoneService {
    private static selectedTimeZone: TimeZone = TimeZone.EuropeBerlin;

    public static getSelected(): TimeZone {
        return this.selectedTimeZone;
    }

    public static setTimeZone(timeZone: TimeZone): void {
        this.selectedTimeZone = timeZone;
    }

    public static formatToTimezone(date: DateInput): Date {
        const timezone: TimeZone = CoreTimezoneService.selectedTimeZone;
        const parsedDate: Date = CoreTimezoneService.parseISO(date);

        return utcToZonedTime(parsedDate, timezone);
    }

    public static formatTimezonedToUTC(date: Date): Date {
        const timezone: TimeZone = CoreTimezoneService.selectedTimeZone;

        return zonedTimeToUtc(date, timezone);
    }

    public static validateIsoString(value: string): boolean {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) {
            return false;
        }
        const testDate: Date = new Date(value);

        return !isNaN(testDate.getTime()) && testDate.toISOString() === value;
    }

    private static parseISO(dateInput: DateInput): Date {
        return typeof dateInput === 'string'
            ? parseISO(dateInput)
            : dateInput;
    }
}

export {CoreTimezoneService, TimeZone, DateInput};
